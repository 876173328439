import React from 'react';
import logo from "./media/img/logo.svg"
import './App.css';

function App() {
  return (
    <img className="logo" src={logo} alt="Todonannies"/>
  );
}

export default App;
